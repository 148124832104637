<template>
  <div class="content">
    <!-- 列表 -->
    <el-table :data="list" height="calc(100vh - 200px)" @sort-change="changeTableSort">
      <el-table-column prop="name" label="姓名" width="130">
        <span slot-scope="scope">{{ scope.row.name }}</span>
      </el-table-column>
      <el-table-column prop="mobile" label="联系方式" width="150">
        <span slot-scope="scope">{{ scope.row.phone }}</span>
      </el-table-column>
      <el-table-column prop="images" label="图片">
        <template slot-scope="scope">
          <img v-if="scope.row.image" style="width: 100px; height: 100px; border-radius: 10px; margin-right: 10px" v-for="(item, index) of scope.row.image" :key="index" :src="item" alt="" />
        </template>
      </el-table-column>
      <el-table-column prop="content" label="内容">
        <span slot-scope="scope">{{ scope.row.text }}</span>
      </el-table-column>
      <el-table-column prop="content" label="提交时间" width="180" align="center">
        <span slot-scope="scope">{{ scope.row.add_time | date('Y-m-d H:i') }}</span>
      </el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="danger" icon="el-icon-delete" @click.native.prevent="toDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @current-change="pagination" layout="total,prev,pager,next,jumper" :current-page="page" :page-size="pageLimit" :total="totalSize"></el-pagination>
    </div>

    <!-- 充值面板 -->
    <recharge ref="recharge" @refreshData="loadList"></recharge>
  </div>
</template>

<script>
import recharge from './components/recharge.vue'
import http from '@/utils/cloud'

export default {
  components: {
    recharge
  },
  data() {
    return {
      filter: {},
      orderBy: {},
      list: [],
      pageLimit: 20,
      page: 1,
      project: localStorage.getItem('mall-project')
    }
  },
  created() {
    // this.loadList();
    this.getPostsaleList()
  },
  methods: {
    /* 获取列表 */
    async getPostsaleList() {
      const list = await http.POST('/jw-admin', {
        module: 'postsale',
        operation: 'getPostsaleList',
        project: JSON.parse(this.project)._id
      })
      console.log(list)
      this.list = list
    },
    /* 删除 */
    toDelete(index, item) {
      this.$confirm(`是否要删除：${item.name}`, '删除提示', {
        confirmButtonText: '删除',
        type: 'warning'
      })
        .then(async () => {
          const response = await http.POST('/jw-admin', {
            module: 'postsale',
            operation: 'deletPostsale',
            id: item._id
          })

          if (response.status === 1) {
            this.$message.success('删除成功')
            this.list.splice(index, 1)
          } else {
            this.$message.error(response.msg)
          }
        })
        .catch(() => {})
    },
    // 获取列表
    // async loadList() {
    //   const { page, pageLimit, filter } = this;
    //   const sendData = {
    //     offset: (page - 1) * pageLimit,
    //     limit: pageLimit,
    //   };
    //   const res = await http.POST('/jw-admin', {
    //     module: 'feedback',
    //     operation: 'get',
    //     ...sendData,
    //   });

    //   console.log(res);
    //   this.totalSize = res.affectedDocs;
    //   this.list = res.data;
    //   this.loaded = true;
    // },
    //封禁、解封
    changeStatus(item) {
      const tip = item.status === 2 ? '解封' : '封禁'
      this.$confirm(`确定要${tip}用户${item.username}吗`, `用户${tip}`, {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async () => {
        const res = await http.POST('/jw-admin', {
          module: 'user',
          operation: 'changeStatus',
          uid: item._id,
          status: item.status == 1 ? 0 : 1
        })

        if (res.status === 1) {
          this.$message.success(`已${tip}用户`)
          this.loadList()
        } else {
          this.$message.error(`${tip}失败`)
        }
      })
    },
    //发货
    recharge(item) {
      this.$refs.recharge.formData = {
        uid: item._id
      }
      this.$refs.recharge.formVisible = true
    },
    // 排序
    changeTableSort(e) {
      this.orderBy = e.order === 'ascending' ? { [e.prop]: 1 } : e.order === 'descending' ? { [e.prop]: -1 } : { add_time: -1 }
      this.page = 1
      this.loadList()
    },
    //搜索
    search() {
      this.page = 1
      this.loadList()
    }
  }
}
</script>

<style scoped lang="scss">
.mr5 {
  margin-right: 5px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.handle-select {
  width: 140px;
}
.handle-input {
  width: 140px;
  display: inline-block;
}
</style>
